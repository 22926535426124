import { Box, TextField, Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';
import LoginProfile from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";


const FormTeam = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const[method,setMethod] = useState(null);
  const [resp, setResp] = useState(null);
  const [manager, setManager] = useState([]);

  const navigate = useNavigate();

  const api = process.env.REACT_APP_API_URL;

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------

  const urlGetTeam = `${api}/team/${id}`;
  const { data } = useFetch(urlGetTeam);


  initialValues.team = `${data.teamName ? data.teamName : ""}`;
  initialValues.product = `${data.product ? data.product : ""}`;
  initialValues.cost = `${data.cost ? data.cost : ""}`;

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/team/cadastro`;

    const team = {
      team_id : id,
      teamName : values.team,
      product : values.product,
      cost : values.cost,
      account_id : LoginProfile.getAccountId()
    };
    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(team),
    });
    setResp(request.status);
    if(request.ok){
      const response = await request.json();
      navigate("/team")
    }
    localStorage.setItem('alter_teams',Date.now());
    
  };
  
  const handleLabel = () => {
    if(id){
      return "Update Team";
    }
      return "Create Team";
  };

  return (
    <Box m="20px">
      <Header title={t(handleLabel())} subtitle={t("Create or Update Team")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Team")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.team}
                name="team"
                error={!!touched.team && !!errors.team}
                helperText={touched.team && errors.team}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Product")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.product}
                name="product"
                error={!!touched.product && !!errors.product}
                helperText={touched.lastNamproducte && errors.product}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <SnackBars buttonName={t(handleLabel().toUpperCase())} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  team: yup.string().required("required"),
  //product: yup.string().required("required"),
});
const initialValues = {
  team: "",
  product: "",
  // cost: "",
};

export default FormTeam;