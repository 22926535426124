import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function CustomizedSnackbars({resp, buttonName, buttonType, disabled}) {
  const [open, setOpen] = React.useState(false);

  
  const handleClick = () => {
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  return (
    <Box display="flex" justifyContent="end" mt="20px">
      <Button variant="contained" onClick={handleClick} type={buttonType} color="secondary" disabled={disabled}>
        {buttonName}
      </Button>
      {resp >= 200 && resp < 300 ?
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          This is a success message!
          </Alert>
        </Snackbar>
      : 
        null
      }
    </Box>
  );
}

/*
<Alert severity="error">This is an error message!</Alert>
<Alert severity="warning">This is a warning message!</Alert>
<Alert severity="info">This is an information message!</Alert>
<Alert severity="success">This is a success message!</Alert>
 */