import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {useEffect, useState} from 'react';
import FAB from "../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'; 
import LoginProfile from "../../hooks/useLoginUser";
import TeamProfile from "../../hooks/useTeam";
import { useLocation } from 'react-router-dom';
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

const mockRows = [{
  "id": 1,
  "teamName": undefined,
  "campaign_name": undefined,
  "people_target": undefined,
  "initial_date": undefined,
  "end_date": undefined,
  "objective": undefined
}];

const ListPDI = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const api = process.env.REACT_APP_API_URL;

  const location = useLocation();
  
  const[list, setList] = useState([]);
  const[campaign, setCampaign] = useState([]);

  let row = [];

  const handleCampaign = (id) => {
    campaign.map(function(item){
      if(item.id === id){
        //setRow(item);
        row = item;
      }
      return item;
    });
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  const columns = [
    {field: "id", headerName: "ID",flex:0.2},
    {
      field: "user",
      headerName: t("Employee"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "manager",
      headerName: t("Manager"),
      flex: 1,
    },
    {
      field: "start_date",
      headerName: t("Start Date"),
      flex: 1,
    },
    {
      field: "end_date",
      headerName: t("End Date"),
      flex: 1,
    },
    {
      field: "type",
      headerName: t("Type"),
      flex: 1,
    },
    {
      field: "objective",
      headerName: t("Objective"),
      flex: 2,
      
    },
  ];

  const handleProfile = (profile) => {
    if (profile === 3){
      return "user";
    }
    else {
      return "manager";
    }
  }

  const fetchData = async () => {
    const url = `${api}/pdi/${handleProfile(LoginProfile.getProfileId())}/${LoginProfile.getUserId()}/language/${localStorage.getItem('language')}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
  
    setCampaign(json);
  };

  useEffect(() => {
    fetchData();
  }, [TeamProfile.getTeamID(),location.pathname]);

  const handleSendTouch = async () => {
    
    if(list.length > 1) {
      list.map(async function(item) {
        const url = `${api}/campaign/enviacampaign`;
        handleCampaign(item);

        const message = {
          campaign_id : row.id,
          campaign_name : row.campaign_name,
          objective : row.objective,
          initial_date : handleDataHora(row.initial_date),
          end_date : handleDataHora(row.end_date),
          people_target : row.people_target,
          campaign_type : row.campaign_type,
          status : "enviado",
          team_id: TeamProfile.getTeamID()
        };
        
        const request = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Max-Age": "86400",
            "Authorization": `${sessionStorage.getItem('token')}`
          },
          body: JSON.stringify(message),
        });
        fetchData();
      });
    }
    else {
      const url = `${api}/campaign/enviacampaign`;

      handleCampaign(parseInt(list));

      const message = {
        campaign_id : row.id,
        campaign_name : row.campaign_name,
        objective : row.objective,
        initial_date : handleDataHora(row.initial_date),
        end_date : handleDataHora(row.end_date),
        people_target : row.people_target,
        campaign_type : row.campaign_type,
        status : "enviado",
        team_id: TeamProfile.getTeamID()
      };
      
      const request = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(message),
      });
      fetchData();
    }
  };

  const handleDelete = async () => {
    if(list.length > 1) {
      list.forEach(async id => {
        const url = `${api}/pdi/${id}`;
        const request = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Max-Age": "86400",
            "Authorization": `${sessionStorage.getItem('token')}`
          },
        });
        fetchData();
      });
    }
    else {
      const url = `${api}/pdi/${list}`;
      const request = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        },
      });
      fetchData();
    }
  };
  
  return (
    <Box m="20px">
      <Header title={t("Personal Development Plan")} subtitle={t("List of Personal Development Plan (PDP)")} />
      <Box
        m="5px 0 0 0"
        height="69vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "solid 1px",
            borderBottomColor: colors.grey[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box  display="flex" mr="10px" justifyContent="end" gap={1} >
          <FAB
           to={`/pdi/actionplan/${list[0]}`}
           name="touch"
           title={t("Action Plan")}
           icon={<IntegrationInstructionsOutlinedIcon />}
           disabled={list.length === 1 ? false : true}
           id={list}
           handleSendTouch={handleSendTouch}
          />
          <FAB
            to="/pdi/cadastro"
            title={t("Add new IDP")}
            icon={<AddIcon />}
            disabled={LoginProfile.getProfileId() === 3 ? true : false}
          />
          <FAB
            to={`/pdi/cadastro/${list[0]}`}
            icon={<EditIcon />}
            title={t("Edit IDP")}
            disabled={list.length === 1 ? false : true}
          />
          <FAB
            to="/pdi"
            icon={<Delete />}
            disabled={LoginProfile.getProfileId() === 3 ? true : list.length >= 1 ? false : true}
            id={list}
            handleDelete={handleDelete}
            name="delete"
            title={t("Delete")}
          />
        </Box>
        <DataGrid 
          checkboxSelection
          rows={campaign ? campaign : false }
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={setList}
      />
      </Box>
    </Box>
  );
};

export default ListPDI;
