import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { mockPaises } from '../../../data/mockPaises copy'
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import {useFetch} from '../../../hooks/useFetch';
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";


const FormManageLogin = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [method,setMethod] = useState(null);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState([]);
  const [login, setLogin] = useState([]);
  const [phoneError, setPhoneError] = useState(null);
  const [phoneCellError, setPhoneCellError] = useState(null);

  //recebe id do account para carregar o form para edição
  
  const { id } = useParams();
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  useEffect(()=> {
    const fetchAccountAddress = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }
      try{
        const [accountResponse] = await Promise.all([
          fetchWithHeaders(`${api}/login/id/${id}`)
        ]);
        setData(accountResponse);
      }
      catch (error){
        console.error('Error fetching data:', error);
      } 
    };

    fetchAccountAddress();
  },[id]);

  initialValues.fullName = `${data.fullName ? data.fullName : ""}`;
  initialValues.email = `${data.email ? data.email : ""}`;
  initialValues.profile = `${data.profile ? data.profile : ""}`;
  initialValues.status = `${data.status ? data.status : ""}`;

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const accountUrl = `${api}/login/update`;
    
    const account = {
      login_id : id,
      status : values.status
    };
   
    const reqAccount = await fetch(accountUrl,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(account),
    });
    setResp(reqAccount.status);
  };
  //------------------------------------------------

  const handleValidaTelefone = (phone) => {
    const telefoneRegEx = /^\([1-9]{2}\)9[0-9]{4}\-[0-9]{4}$/;
    if (telefoneRegEx.test(phone)){
      return true;
    }
    else{
      return false;
    }
  };

  const handleLabel = () => {
    if(id){
      return "Update Login";
    }
      return "Create Login";
  };

  return (
    <Box m="20px">
      <Header title={t(handleLabel())} subtitle={t("Update Login")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                disabled
                fullWidth
                variant="filled"
                type="text"
                label="Login"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <InputLabel>{t("Status")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Status")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status}
                  name="status"
                  error={!!touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
                  sx={{ gridColumn: "span 4" }}
                  >
                    <MenuItem value="enable">{t("enable")}</MenuItem>
                    <MenuItem value="disable">{t("disable")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName={t(handleLabel().toUpperCase())} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
});
const initialValues = {
  fullName: "",
  profile : "",
  status : ""
};

export default FormManageLogin;