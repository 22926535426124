import { Box, IconButton, useTheme, Menu, MenuItem, Tooltip, Zoom, Typography, Avatar, Divider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from '@mui/icons-material/Group';
import LoginProfile from "../../hooks/useLoginUser";
import TeamProfile from "../../hooks/useTeam";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Context } from "../../components/Auth";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';

const Topbar = () => {
  const {t, i18n} = useTranslation();
  const { handleLogout } = useContext(Context);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [teamSelected, setTeamSelected] = useState(null);


  const api = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElLenguage, setAnchorElLenguage] = useState(null);
  const openLenguage = Boolean(anchorElLenguage);

  const [anchorElTeam, setAnchorElTeam] = useState(null);
  const openTeam = Boolean(anchorElTeam);

  const [team, setTeam] = useState([]);

  let permissions = [];
  const storedPermissions = sessionStorage.getItem("permissions");
  if (storedPermissions) {
    permissions = storedPermissions.split(",").map(Number);
  }


  const fetchData = async () => {
    const url = `${api}/team/manager/${LoginProfile.getUserId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": sessionStorage.getItem('token')
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setTeam(json);
    //selecionar automaticamente o primeiro time
    TeamProfile.setTeamID(json[0].id);
    TeamProfile.setTeamName(json[0].teamName);
    //setTeamSelected(json[0].teamName);
  };

  const fetchLanguage = async () => {
    const url = `${api}/language/${LoginProfile.getAccountId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": sessionStorage.getItem('token')
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    
    localStorage.setItem("language",json.language);
  };

  useEffect(() => {
    fetchData();
    fetchLanguage();
  },[localStorage.getItem('alter_teams')]);

  const handleClickTeam = (event) => {
    setAnchorElTeam(event.currentTarget);
  };

  const handleTeamClick = (event) => {
    //TeamProfile.setTeamID(event.target.id);
    //TeamProfile.setTeamName(event.target.innerText)
    setTeamSelected(event.target.attributes.to.nodeValue);
    navigate(event.target.attributes.to.nodeValue);
    console.log('handle', event.target.attributes.to.nodeValue);
  };


  const handleCloseTeam = () => {
    setAnchorElTeam(null);
  };

  function stringAvatar(name) {
    return `${name.split(' ')[0][0]}`;
    //return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }

  const handleHavePermission = (permission) => {
    let permissionsArray = [];
    const storedPermissions = sessionStorage.getItem("permissions");
    if (storedPermissions) {
      permissionsArray = storedPermissions.split(',').map(Number);
      return permission && !permissionsArray.includes(permission);
    }
    return true;
  }
 
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <Tooltip title={<Typography fontSize={12}>{theme.palette.mode + " mode"} </Typography>} TransitionComponent={Zoom} >
          <IconButton onClick={colorMode.toggleColorMode} >
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={<Typography fontSize={12}> {t("Insights")} </Typography>} TransitionComponent={Zoom} >
          <IconButton>
            <TipsAndUpdatesOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={<Typography fontSize={12}> {"Configuration"} </Typography>} TransitionComponent={Zoom} >
          <IconButton
            onClick={handleClickTeam}
            aria-controls={openTeam ? 'team-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openTeam ? 'true' : undefined}
          >
            <SettingsOutlinedIcon />
          </IconButton>
        </Tooltip>
        <IconButton>
        <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${localStorage.getItem("language")}.png 2x`}
            src={`https://flagcdn.com/w20/${localStorage.getItem("language")}.png`}
            alt={t("language")}
            title={t("language")}
            onClick={() => {
              navigate('/account/language');
            }}
          />
        </IconButton>
        <Tooltip title={<Typography fontSize={12}>{LoginProfile.getFullName()}</Typography>} >
          <IconButton>
            <Avatar
              sx={{ width: 24, height: 24 }}
              //src={LoginProfile.getPicture()}
              onClick={handleLogout}
            >
              {stringAvatar(LoginProfile.getFullName() && LoginProfile.getFullName() )}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorElTeam}
          id="team-menu"
          open={openTeam}
          onClose={handleCloseTeam}
          onClick={handleCloseTeam}
          sx={{
            '& .MuiPaper-root': {
              elevation: 0,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              bgcolor: colors.blueAccent[900],
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                bgcolor: theme.palette.background.paper,
              },
              '& .MuiMenuItem-root': {
                fontSize: 16,
                minWidth: 150,
                color: theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: theme.palette.grey[700],
                },
              },
              '& .Mui-selected': {
                backgroundColor: '#868dfb !important',
                color: theme.palette.secondary.contrastText,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            name={"user"}
            onClick={handleTeamClick}
            to={'/user'}
            selected={location.pathname === '/user' ? 'selected' : undefined}
            id={1}
            permission={65}
            sx={{gap:"10px"}}
            disabled={handleHavePermission(65)}
          >
            <PersonOutlinedIcon />
            {t("Manage Users")}
          </MenuItem>
          <Divider />
          <MenuItem
            name={"team"}
            onClick={handleTeamClick}
            to={'/team'}
            selected={location.pathname === '/team' ? 'selected' : undefined}
            id={2}
            permission={66}
            disabled={handleHavePermission(66)}
            sx={{gap:"10px"}}
          >
            <GroupOutlinedIcon />
            {t("Manage Team")}
          </MenuItem>
          <Divider />
          <MenuItem
            name={"login"}
            onClick={handleTeamClick}
            to={'/managelogin'}
            selected={location.pathname === '/managelogin' ? 'selected' : undefined}
            id={3}
            permission={67}
            disabled={handleHavePermission(67)}
            sx={{gap:"10px"}}
          >
            <LoginOutlinedIcon />
            {t("Manage Login")}
          </MenuItem>
          <Divider />
          <MenuItem
            name={"account"}
            onClick={handleTeamClick}
            to={`/formAccount/${LoginProfile.getAccountId()}`}
            selected={location.pathname === `/formAccount/${LoginProfile.getAccountId()}` ? 'selected' : undefined}
            id={4}
            permission={68}
            disabled={handleHavePermission(68)}
            sx={{gap:"10px"}}
          >
            <AccountBalanceOutlinedIcon />
            {t("Manage Account")}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
